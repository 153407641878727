const query = `
  query {
    user {
      fullName
    }
  }
`;

interface FullNameResponse {
  user?: {
    fullName?: string;
  };
}

export const useFullName = () => {
  const { $gql } = useNuxtApp();

  return useHurstQuery({
    queryKey: ["USER-FULL-NAME"],
    async queryFn() {
      const res = await $gql<FullNameResponse>(query);
      return res.user?.fullName;
    },
    staleTime: 60 * 60 * 1000,
  });
};
