<script setup lang="ts">
import { Icon } from "@iconify/vue";
import AppLogo from "@/components/private/AppLogo";
import AppMobileNav from "@/components/private/AppMobileNav";
import Avatar from "@/components/private/Avatar";
import { initialLetterName } from "@/utils/user/initial-letters-name";
import { useFullName } from "~/composables/api/account/useFullName";

const appMobileNavIsOpen = ref(false);
const handleCloseMenu = () => {
  appMobileNavIsOpen.value = false;
};

const fullName = useFullName();

const initialLetter = computed(() => {
  return initialLetterName(fullName?.data?.value);
});
</script>

<template>
  <header
    class="h-[81px] bg-[#171c20] fixed top-0 left-0 xl:left-[200px] right-0 pl-4 pr-4 border-b-2 border-[#374148] z-10 max-w-[100vw]"
  >
    <nav class="h-full flex justify-between items-center">
      <PrivateBreadcrumbs class="max-xl:hidden" />
      <div class="px-7 xl:hidden">
        <NuxtLink to="/" class="block">
          <AppLogo />
        </NuxtLink>
      </div>

      <div class="flex items-center justify-end px-7">
        <span class="max-md:hidden flex">
          <a
            href="/oportunidades"
            class="bg-[#1f282e] text-white flex items-center justify-start mr-5 px-5 py-[10px] rounded-[10px] min-w-[140px] font-nunito text-sm font-semibold"
          >
            <Icon
              icon="ic:baseline-arrow-right-alt"
              width="18"
              height="18"
              class="mr-[10px]"
            />
            Investir
          </a>
          <Avatar :initials="initialLetter" :url="'/painel/perfil'" />
        </span>
        <button
          class="cursor-pointer xl:hidden ml-5"
          :class="{
            'rotate-90': appMobileNavIsOpen,
            'rotate-0': !appMobileNavIsOpen,
          }"
          aria-label="Menu"
          @click="() => (appMobileNavIsOpen = !appMobileNavIsOpen)"
        >
          <Icon icon="ic:baseline-dehaze" width="24" height="24" color="#FFF" />
        </button>
        <AppMobileNav
          :open="appMobileNavIsOpen"
          @close-menu="handleCloseMenu"
        />
      </div>
    </nav>
  </header>
</template>
