import { type NavItemDraw } from "./protocols";
export const items: NavItemDraw[] = [
  {
    label: "Início",
    to: "/painel",
    code: "root",
    icon: "tdesign:home",
  },
  {
    label: "Wallet",
    to: "/painel/wallet",
    code: "wallet",
    img: "/short-logo-hurst-white.svg",
    imgActive: "/short-logo-hurst.svg",
  },
  {
    label: "Pendentes",
    to: "/painel/pendentes",
    code: "pending",
    icon: "ph:target-bold",
  },
  // {
  //   label: "Indicação",
  //   to: "/painel/indicacao",
  //   code: "indication",
  //   icon: "mdi:account-supervisor",
  // },
  {
    label: "Declaração IR",
    to: "/painel/ir",
    code: "ir",
    icon: "mdi:file-text-multiple-outline",
  },
  {
    label: "Ajuda",
    to: "/painel/ajuda",
    code: "help",
    icon: "bx:user",
  },
];
