<script setup lang="ts">
import { vOnClickOutside } from "@vueuse/components";
import { defineEmits, defineProps, ref } from "vue";
import { useRoute } from "vue-router";
import { initialLetterName } from "@/utils/user/initial-letters-name";
import { items } from "@/components/private/Sidebar/_nav";
import { useFullName } from "~/composables/api/account/useFullName";

const fullName = useFullName();

export interface AppMobileNavProps {
  open: boolean;
}

withDefaults(defineProps<AppMobileNavProps>(), {
  open: false,
});

const initialLetter = computed(() => {
  return initialLetterName(fullName?.data?.value);
});

const isSubMenuOpen = ref(false);

const emits = defineEmits(["close-menu"]);

const toggleSubMenu = () => {
  isSubMenuOpen.value = !isSubMenuOpen.value;
};

function close() {
  isSubMenuOpen.value = false;
}

const closeMenu = () => {
  emits("close-menu");
};

const route = useRoute();

const isLinkActive = (to: string) => {
  return route.path === to;
};
</script>

<template>
  <nav
    :class="`fixed left-0 top-[80px] h-auto w-full bg-dark-slate-gray xl:hidden ${
      open ? 'block' : 'hidden'
    }`"
  >
    <div>
      <ul v-on-click-outside="close">
        <li
          v-for="item in items"
          :key="item.code"
          class="border-b border-[#374148]"
          @click="toggleSubMenu"
        >
          <button class="w-full" @click="closeMenu">
            <NuxtLink
              :to="item.to"
              :class="[
                'flex items-center gap-x-[8px] text-gray-100 p-3 font-Nunito text-sm font-semibold whitespace-nowrap px-10 py-5',
                { 'text-primary-500 bg-black ': isLinkActive(item.to) },
              ]"
              :exact="true"
            >
              <span v-if="item.icon">
                <Icon
                  v-if="item.icon"
                  :name="item.icon"
                  class="mr-2"
                  :class="{ 'text-primary-300': isLinkActive(item.to) }"
                  size="18"
                />
              </span>
              <span v-if="item.img">
                <img
                  v-if="isLinkActive(item.to)"
                  preload
                  :src="item.imgActive"
                  width="16"
                  height="16"
                  class="mr-3"
                />
                <img
                  v-else
                  preload
                  :src="item.img"
                  width="16"
                  height="16"
                  class="mr-3"
                />
              </span>
              {{ item.label }}
            </NuxtLink>
          </button>
        </li>

        <li class="md:hidden" @click="toggleSubMenu">
          <NuxtLink
            :to="'/painel/perfil'"
            class="flex items-center gap-x-[14px] text-gray-100 p-3 font-'Nunito' text-sm font-semibold whitespace-nowrap hover:bg-black px-10 py-5"
            @click="closeMenu"
          >
            <div
              class="bg-gray-100 text-gray-600 text-center text-[10px] w-5 h-5 rounded-full flex items-center justify-center"
            >
              {{ initialLetter }}
            </div>
            Meu Perfil
          </NuxtLink>
        </li>
      </ul>
    </div>
  </nav>
</template>
